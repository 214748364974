import React from "react";
import { StyledCustomTooltip } from "./index.css";

function CustomTooltip(props) {
  return (
    <StyledCustomTooltip
      className={props.className}
      title={props.title}
      placement={props.placement}
      arrow
    >
      {props.children}
    </StyledCustomTooltip>
  );
}

export default CustomTooltip;
