import styled from "styled-components";
import Grid from "@material-ui/core/Grid";


export const StyledFooter = styled(Grid)`
  align-items: right;
  bottom: 0;
  display: flex;
  padding-top: 10px;
  padding-right: 4vw;
  justify-content: flex-end;

  .footer-link{
    .typo {
      margin: 5px;
      color:  #00a5c0;
      :hover {
        text-decoration: underline;
      }
    } }
`;