export const DEFAULT_VALUES = {
  isin: {
    value: "",
    operator: "=",
  },
  productName: {
    value: "",
    operator: "=",
  },

  issuerName: {
    value: "",
    operator: "=",
  },
  issuerLEI: {
    value: "",
    operator: "=",
  },
  lastUpdateDate: {
    value: null,
    range: { from: null, to: null },
    operator: "[]",
  },
};

export const OPERATORS_ALLOWED = {
  lastUpdateDate: ["=", "[]"],
};

export const ERROR_DEFAULT_VALUES = {
  isin: {
    error: false,
    helperText: "",
    length: 12,
    pattern: "^[a-zA-Z0-9]*$",
  },
  productName: {
    error: false,
    helperText: "",
    length: 100,
    pattern: "^[a-zA-Z0-9+.:=_/\\- ]*$",
  },
  issuerName: {
    error: false,
    helperText: "",
    length: 100,
    pattern: "^[a-zA-Z0-9+.:=_/\\- ]*$",
  },
  issuerLEI: {
    error: false,
    helperText: "",
    length: 20,
    pattern: "^[a-zA-Z0-9+.:=_/\\- ]*$",
  },
};
