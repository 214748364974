import { DEFAULT_VALUES } from "../modules/Views/Query/QueryNListView/QueryView/components/QueryViewContent/components/SearchCriteria/constants";

export const initialState = {
  queries: DEFAULT_VALUES,
  expandView: { query: true, list: false },
  queryFired: false,
  list: [],
  historicList: [],
  noMoreList: false,
  queryBuild: {},
  listRefresh: false,
  queryExecutionTime: "",
  continuationToken: "",
  loading: false,
};
