import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const StyledHeader = styled(Grid)`
  align-items: center;
  margin-bottom: 38px;
  .nav {
    padding: 1px 10px;
    color: black;
  }
  .nav-tab {
    border-right: 1px solid #00a5c0;
    color: #00a5c0 !important;
  }
  .grid-items {
    display: flex;
    justify-content: flex-end;
  }
`;
