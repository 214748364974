import styled from "styled-components";
import Accordion from "@material-ui/core/Accordion";

export const StyledModuleExpansion = styled(Accordion)`
  margin-bottom: 10px;
  border: 3px solid #a5e6ee;
  &.hidden {
    display: none;
  }
  display: block;
  &.MuiPaper-root {
    margin-top: 0px;
  }
  img {
    padding-right: 11px;
  }
  .summary {
    background-color: #a5e6ee;
    height: 66px;
  }
  background-color: #d3f3f7;
  .MuiAccordionDetails-root {
    display: grid;
    padding: 0 !important;
  }
  .divider {
    border: 1px solid #00a7c4;
    width: 100%;
    margin: 0;
  }
  .MuiCollapse-container {
    margin-bottom: 10px;
  }
  .buttons {
    height: 34px;
    margin: 10px;
    padding: 0px;
    padding-left: 24px;
    padding-right: 25px;
    color: white;
    white-space: nowrap;
  }
  .clear-button {
    font-family: "FFDINBold";
    height: 34px;
    margin: 10px;
    padding: 0px;
    padding-left: 24px;
    padding-right: 25px;
    white-space: nowrap;
  }
  .display-inline {
    margin-top: 10px;
    margin-bottom: 15px;
    display: inline-block;
    color: black;
  }
  .MuiButton-text {
    color: #00a7c4;
  }
  .disable-button {
    color: #a8bdc1 !important;
  }
  `;