import React from "react";
import logo from "../../assets/logo.svg";
import Grid from "@material-ui/core/Grid";
import { StyledHeader } from "./StyledHeader";
function Header() {
  return (
    <StyledHeader container>
      <Grid item xs={6}>
        <img src={logo} alt="logo" />
      </Grid>
      {/* Will be required in next iterations
      
      <Grid className="grid-items" item xs={6} align="right">
        <Link className="nav nav-tab">
          <Typography variant="h5">Help</Typography>
        </Link>
        <Link className="nav nav-tab">
          <Typography variant="h5">My Profile</Typography>
        </Link>
        <Link className="nav nav-tab">
          <Typography variant="h5">Contacts</Typography>
        </Link>
        <Link className="nav nav-tab">
          <Typography variant="h5">Logout</Typography>
        </Link>
        <Typography variant="h4" className="nav" variant="h5">
          25 June 2021 15:30:35 CET
        </Typography>
      </Grid>*/}
    </StyledHeader>
  );
}

export default Header;
