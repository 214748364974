import styled from "styled-components";
import { Dialog } from "@material-ui/core";

export const StyledDialog = styled(Dialog)`
  .MuiPaper-root {
    overflow: hidden;
    background-color: transparent;
  }
  .MuiCircularProgress-svg {
    color: #00a7c4;
    filter: brightness(0.7);
  }
  .MuiBackdrop-root {
    opacity: 0.5;
  }
  .MuiPaper-elevation24 {
    box-shadow: none;
  }
  .MuiDialog-paper {
    overflow-y: hidden;
  }
  .MuiCircularProgress-root {
    width: 50%;
    height: 50%;
  }
`;

export const StyledApp = styled.div`
display: flex;
min-height: 100vh;
flex-direction: column;
justify-content: space-between;
.container {
  padding-top: 40px;
  padding-left: 51px;
  padding-right: 52px;
}
`;
