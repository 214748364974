import styled from "styled-components";
import { Grid } from "@material-ui/core";
export const StyledGrid = styled(Grid)`
.display-flex-end {
  display: flex;
  justify-content: flex-end;
}
  z-index: 2;
  .responsive-div {
    z-index: 2;
    position: absolute;
    margin-top: 35px;
  }

  .count-div {
    border: 3px solid #00a7c4;
    color: #00a7c4;
    background-color: white;
    height: 50px;
    width: 330px;
    margin-right: 40px;
    .text {
      text-align: center;
      padding: 5px;
    }
  }
  .count-arrow {
    margin-right: 160px;
    margin-top: 12px;
  }
`;
