import React from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import { StyledModuleExpansion } from "./StyledModuleExpansion";

function ModuleExpansion(props) {
  return (
    <StyledModuleExpansion
      square
      expanded={props.expanded}
      onChange={props.toggleExpandView}
      className={props.hidden && "hidden"}
    >
      <AccordionSummary className="summary" id="summary">
        {props.summary}
      </AccordionSummary>
      <AccordionDetails>{props.children}</AccordionDetails>
    </StyledModuleExpansion>
  );
}

export default ModuleExpansion;
