import React from "react";
import SearchCriteria from "./components/SearchCriteria";
import { Grid } from "@material-ui/core";
function QueryViewContent() {
  return (
    <Grid container>
      <SearchCriteria />
    </Grid>
  );
}

export default QueryViewContent;
