import { Store } from "./Store";
import {useReducer} from "react";
import {initialState} from "./DefaultStates";
import {Reducer} from "./Reducer"

export const StoreProvider = ({ children }) => {
  const [globalState, dispatch] = useReducer(Reducer, initialState);

  return (
    <Store.Provider value={[globalState, dispatch]}>{children}</Store.Provider>
  );
};
