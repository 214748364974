import TableHead from "@material-ui/core/TableHead";
import styled from "styled-components";

export const StyledTableHead = styled(TableHead)`
  .MuiTableCell-root {
    font-family: "FFDINBold";
    font-size: 14px;
    white-space: nowrap;
    background-color: #d3f3f7;
    border: 1px solid #a5e6ee;
    padding-top: 0px;
    padding-bottom: 0px;
    .MuiTableSortLabel-root {
      color: black;
    }
  }

  .MuiTableRow-root {
    height: 38px;
  }
  background-color: #d3f3f7;

  .visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1;
    margin: -1;
    overflow: hidden;
    padding: 0;
    position: absolute;
    top: 20;
    width: 1;
  }
`;
