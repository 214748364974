import React, { useState } from "react";
import { Tab } from "@material-ui/core";
import { StyledModuleNavigation } from "./StyledModuleNavigation";
import QueryNListView from "../../modules/Views/Query/QueryNListView";

function ModuleNavigation(props) {
  const [value, setValue] = useState(0);

  return (
    <>
      <StyledModuleNavigation value={value}>
        <a
          href="https://www.clearstream.com/clearstream-en/securities-services/issuance-1-/electronic-securities"
          target="_blank"
          title="Public Security Terms"
          className="termsLink"
        >
          <Tab className="active-nav-tab" label="Public Security Terms" />
        </a>
      </StyledModuleNavigation>

      <QueryNListView />
    </>
  );
}

export default ModuleNavigation;
