import DINMedium from "./assets/fonts/FFDIN/DIN-Medium.ttf";
import DINRegular from "./assets/fonts/FFDIN/DIN.ttf";
import DINBold from "./assets/fonts/FFDIN/DIN-Bold.ttf";

export const dinBold = {
    fontFamily: "FFDINBold",
    src: `
    local('FFDINBold'),
    local('DIN-Bold'),
    url(${DINBold}) format('truetype')`,
  };

export const dinMedium = {
    fontFamily: "FFDINMedium",
    src: `
    local('FFDINMedium'),
    local('DIN-Medium'),
    url(${DINMedium}) format('truetype')`,
  };
  
export const dinRegular = {
    fontFamily: "FFDINRegular",
    src: `
    local('FFDINRegular'),
    local('DIN-Regular'),
    url(${DINRegular}) format('truetype')`,
  };