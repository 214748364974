import { createTheme } from "@material-ui/core";
import { dinBold, dinRegular, dinMedium } from "./fonts";

export const theme = createTheme({
  typography: {
    fontFamily: ["FFDINRegular", "FFDINMedium", "FFDINBold"].join(","),
    fontSize: 14,
    button: {
      textTransform: "none",
    },
    h1: {
      fontFamily: "FFDINRegular",
      fontSize: 28,
    },
    h2: {
      fontFamily: "FFDINBold",
      fontSize: 17,
    },
    h3: {
      fontFamily: "FFDINMedium",
      fontSize: 17,
    },
    h4: {
      fontFamily: "FFDINRegular",
      fontSize: 17,
    },
    h5: {
      fontFamily: "FFDINBold",
      fontSize: 14,
    },
    h6: {
      fontFamily: "FFDINMedium",
      fontSize: 14,
    },
    subtitle1: {
      fontFamily: "FFDINRegular",
      fontSize: 14,
    },
    subtitle2: {
      fontFamily: "FFDINBold",
      fontSize: 12,
    },
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [dinBold, dinMedium, dinRegular],
        "*::-webkit-scrollbar-track": {
          backgroundColor: "#d6f3f7",
        },

        "*::-webkit-scrollbar-thumb": {
          boxShadow: "inset 0 0 6px #52c3d6",
          width: "15px",
          background: "#52c3d6",
        },

        "*::-webkit-scrollbar": {
          width: "1px",
        },
        "*::-webkit-scrollbar-button": {
          backgroundImage: "unset",
        },
      },
    },

    MuiButton: {
      root: { borderRadius: "0px" },
      containedPrimary: {
        backgroundColor: "#00A7C4",
        fontSize: 14,
        fontFamily: "FFDINBold",
        "&:hover": {
          backgroundColor: "#00A7C4",
          "@media (hover:none)": {
            backgroundColor: "#00A7C4",
          },
        },
      },
      outlinedPrimary: {
        color: "#00A7C4",
        backgroundColor: "white",
        fontSize: 14,
        fontFamily: "FFDINBold",
        border: "none",
        "&:hover": {
          border: "1px solid #00A7C4",
          backgroundColor: "white",
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: "14px",
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        backgroundColor: "white",
        "&$focused $notchedOutline": {
          borderColor: "rgb(38, 152, 255)",
          borderWidth: 2,
        },
      },
    },
  },
});
