import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const StyledFormView = styled(Grid)`
  .title {
    padding: 6px 6px;
    padding-left: 30px;
    width: 155px;
    background-color: #00a7c4;
    margin-bottom: 50px;
    color: white;
  }
  .forms {
    padding-left: 33px;
    margin-bottom: 50px;
  }

`;
