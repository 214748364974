import React, { useEffect } from "react";
import Dropdown from "../../../Dropdown";
import { Typography, Button, TextField } from "@material-ui/core";
import { StyledTablePagination } from "./index.css";
import leftArrow from "../../../../assets/left-arrow.svg";
import rightArrow from "../../../../assets/right-arrow.svg";
import { Grid } from "@material-ui/core";
import moment from "moment-timezone";

function TablePagination(props) {
  const {
    totalRows,
    rowPerPageOptions,
    lastPage,
    rowsPerPage,
    gotoPageNumber,
    onChangeRowsPerPage,
    noMorePages,
    loadMorePages,
    listRefresh,
    displayTime,
  } = props;
  const [startIndex, setStartIndex] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(startIndex);
  const [isMoreEnabled, setIsMoreEnabled] = React.useState(true);
  const [openTextBox, setOpenTextBox] = React.useState(false);
  const [goToValue, setGoToValue] = React.useState();
  const [time, setTime] = React.useState(displayTime);
  const dateTimeFormat = "DD/MM/YYYY HH:mm:ss";
  const timezone = "Europe/Berlin";

  const changeGotoValue = (e) => {
    setGoToValue(e.target.value);
  };

  const onBlurGotoValue = () => {
    const index = parseInt(goToValue);
    if (index >= 1 && index <= lastPage) {
      if (index % 4 == 1) {
        setStartIndex(index);
      } else if (index % 4 == 2) {
        setStartIndex(index - 1);
      } else if (index % 4 == 3) {
        setStartIndex(index - 2);
      } else if (index % 4 == 0) {
        setStartIndex(index - 3);
      }
      setPage(index);
    }
  };
  const setPage = (pageIndex) => {
    gotoPageNumber(pageIndex - 1);
    setCurrentPage(pageIndex);
  };

  useEffect(() => {
    if (noMorePages) {
      setIsMoreEnabled(false);
      if (lastPage % 4 == 1) {
        setStartIndex(lastPage);
      } else if (lastPage % 4 == 2) {
        setStartIndex(lastPage - 1);
      } else if (lastPage % 4 == 3) {
        setStartIndex(lastPage - 2);
      } else if (lastPage % 4 == 0) {
        setStartIndex(lastPage - 3);
      }
      setPage(lastPage);
    } else {
      setIsMoreEnabled(true);
    }
    if (listRefresh) {
      setPage(1);
      setStartIndex(1);
    }
    if (displayTime) {
      setTime(displayTime);
    }
  }, [noMorePages, listRefresh, displayTime]);

  const checkAndLoadMorePages = () => {
    setIsMoreEnabled(true);
    loadMorePages();
    if (lastPage % 4 == 1) {
      setStartIndex(lastPage);
    } else if (lastPage % 4 == 2) {
      setStartIndex(lastPage - 1);
    } else if (lastPage % 4 == 3) {
      setStartIndex(lastPage - 2);
    } else if (lastPage % 4 == 0) {
      setStartIndex(lastPage - 3);
    }
    setPage(lastPage);
  };
  const lastSeriesOfPage =
    startIndex === lastPage ||
    startIndex + 1 === lastPage ||
    startIndex + 2 === lastPage ||
    startIndex + 3 === lastPage;

  return (
    <StyledTablePagination>
      <Grid item xs={6} className="left-div">
        <Typography variant="subtitle2" className="nowrap">
          Rows per page
        </Typography>
        <Dropdown
          isClearable={false}
          className="basic-single"
          classNamePrefix="select"
          placeholder=""
          name="rowsPerPage"
          id="rowsPerPage"
          options={rowPerPageOptions}
          onChange={onChangeRowsPerPage}
          value={{ label: rowsPerPage, value: rowsPerPage }}
        />
        <Typography
          variant="subtitle2"
          className="nowrap"
        >{`${totalRows} rows fetched  ${  
          displayTime
            ? `| Query executed:
            ${moment.utc(time).tz(timezone).format(dateTimeFormat)}`
            : ""
        }`}</Typography>
      </Grid>
      <Grid item xs={6} justify="flex-end" className="right-div">
        {openTextBox === false ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setOpenTextBox(true);
            }}
          >
            Go to
          </Button>
        ) : (
          <TextField
            className="text-field"
            placeholder=""
            variant="outlined"
            id="goto"
            onChange={changeGotoValue}
            value={goToValue}
            onBlur={onBlurGotoValue}
          />
        )}
        <Button
          disabled={currentPage <= 1}
          className="div-button"
          onClick={() => {
            setPage(currentPage - 1);
            if (currentPage % 4 === 1) {
              setStartIndex(currentPage - 4);
            }
          }}
        >
          <img
            alt="prev"
            src={leftArrow}
            className={`${currentPage <= 1 ? "img-disabled" : ""}`}
          />
        </Button>
        {startIndex <= lastPage && startIndex >= 1 && (
          <Button
            className={`${
              startIndex === currentPage
                ? "active-div-button div-button"
                : "div-button"
            }`}
            onClick={() => setPage(startIndex)}
          >
            {startIndex}
          </Button>
        )}
        {startIndex + 1 <= lastPage && startIndex + 1 >= 1 && (
          <Button
            className={`${
              startIndex + 1 === currentPage
                ? "active-div-button div-button"
                : "div-button"
            }`}
            onClick={() => setPage(startIndex + 1)}
          >
            {startIndex + 1}
          </Button>
        )}
        {startIndex + 2 <= lastPage && startIndex + 2 >= 1 && (
          <Button
            className={`${
              startIndex + 2 === currentPage
                ? "active-div-button div-button"
                : "div-button"
            }`}
            onClick={() => setPage(startIndex + 2)}
          >
            {startIndex + 2}
          </Button>
        )}
        {startIndex + 3 <= lastPage && startIndex + 3 >= 1 && (
          <Button
            className={`${
              startIndex + 3 === currentPage
                ? "active-div-button div-button"
                : "div-button"
            }`}
            onClick={() => setPage(startIndex + 3)}
          >
            {startIndex + 3}
          </Button>
        )}
        {!lastSeriesOfPage && startIndex + 4 === lastPage && (
          <Button
            className={`${
              lastPage === currentPage
                ? "active-div-button div-button"
                : "div-button"
            }`}
            onClick={() => setPage(lastPage)}
          >
            {lastPage}
          </Button>
        )}
        {isMoreEnabled && (
          <Button className="div-button align-center">...</Button>
        )}

        {!lastSeriesOfPage && startIndex + 4 !== lastPage && isMoreEnabled && (
          <Button
            className={`${
              lastPage === currentPage
                ? "active-div-button div-button"
                : "div-button"
            }`}
            onClick={() => setPage(lastPage)}
          >
            {lastPage}
          </Button>
        )}

        {isMoreEnabled && (
          <Button className="div-button" onClick={checkAndLoadMorePages}>
            more
          </Button>
        )}

        <Button
          disabled={currentPage >= lastPage}
          className="div-button"
          onClick={() => {
            if (currentPage === lastPage) {
              setStartIndex(currentPage);
            } else {
              if (currentPage % 4 === 0) {
                setStartIndex(currentPage + 1);
              }
              setPage(currentPage + 1);
            }
          }}
        >
          <img
            className={`${currentPage < lastPage ? "" : "img-disabled"}`}
            src={rightArrow}
            alt="next"
          />
        </Button>
      </Grid>
    </StyledTablePagination>
  );
}

export default TablePagination;
