import React from "react";
import ModuleExpansion from "../../../../components/ModuleExpansion";
import { useStore } from "../../../../store/Store";
import QueryViewContent from "./QueryView/components/QueryViewContent";
import ListViewContent from "./ListView/components/ListViewContent";
import QueryViewAccordianSummary from "./QueryView/components/QueryViewAccordianSummary";
import ListViewAccordianSummary from "./ListView/components/ListViewAccordianSummary";
import { toggleExpandView } from "../../../../store/StoreActions";
import infoImg from "../../../../assets/info.svg";
import { StyledNoDataDiv } from "./index.css";
import { Typography } from "@material-ui/core";
function QueryNListView() {
  const [globalState, dispatch] = useStore();
  return (
    <div>
      <ModuleExpansion
        expanded={globalState.expandView.query}
        toggleExpandView={(e, val) => {
          dispatch(toggleExpandView({ query: val }));
        }}
        summary={<QueryViewAccordianSummary summary="Query" />}
      >
        <QueryViewContent />
      </ModuleExpansion>
      <ModuleExpansion
        expanded={globalState.expandView.list}
        toggleExpandView={(e, val) => {
          dispatch(toggleExpandView({ list: val }));
        }}
        summary={<ListViewAccordianSummary summary="List" />}
      >
        {globalState.list.length >= 1 ? (
          <div style={{ overflow: "auto" }}>
            <ListViewContent />
          </div>
        ) : (
          <StyledNoDataDiv>
            <div>
              <img src={infoImg} alt="info" />
              <Typography className="nodata-typo" variant="h6">
                No rows available to display
              </Typography>
            </div>
          </StyledNoDataDiv>
        )}
      </ModuleExpansion>
    </div>
  );
}

export default QueryNListView;
