import styled from "styled-components";
import CustomTooltip from "../CustomTooltip";

export const StyledDiv = styled.div`
  overflow: hidden;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
`;

export const StyledCustomTooltip = styled(CustomTooltip)`
  .MuiTooltip-arrow {
    left: 2% !important;
  }
  .MuiTooltip-tooltip {
    height: 30px;
    font-size: 12px;
    max-width: unset !important;
  }

  .MuiTooltip-tooltipPlacementTop {
    margin: 2px 0 !important;
  }
`;
