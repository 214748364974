import styled from "styled-components";
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
//import Grid from "@material-ui/core/Grid";

export const StyledTableRow = styled(TableRow)`
  *&:nth-of-type(odd) {
    background-color: #fff8d8;
  }
  &:nth-of-type(even) {
    background-color: #ffffff;
  }
  height: 38px;
  &.selected-cell {
    background-color: #99cc33;
  }
  .overflow-td {
    width: 23ch;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  background-color: #d3f3f7;

  .MuiTable-root {
    width: 100%;
    // min-width: 750;
    margin-top: 8px;
  }
`;
export const StyledTableBody = styled(TableBody)`
  .MuiTableCell-root {
    border: 1px solid #a5e6ee;
    padding-top: 0px;
    padding-bottom: 0px;
    padding-right: 0px;
    white-space: nowrap;
  }
  .download {
    cursor: pointer;
  }
`;
