import React from "react";
import Typography from "@material-ui/core/Typography";
import collapsedImg from "../../../../../../../assets/expand-more.svg";
import expandedImg from "../../../../../../../assets/expand-less.svg";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useStore } from "../../../../../../../store/Store";
import { StyledGrid } from "./index.css";
import axios from "axios";
import {
  resetList,
  noMoreList,
  error,
  appLoading,
} from "../../../../../../../store/StoreActions";
import { QUERY_CRITERIA } from "../../../../../../../config/ApiUrls";

function ListViewAccordianSummary(props) {
  const [globalState, dispatch] = useStore();

  const expanded = globalState.expandView.list;

  const data = globalState.list.map((list) => {
    return {
      isin: list.isin,
      productName: list.productName,
      issuerName: list.issuerName,
      lastUpdateDate: list.lastUpdateDate,
      issuerLEI: list.issuerLEI,
    };
  });

  const refreshList = (event) => {
    event.stopPropagation();
    dispatch(appLoading(true));
    let { inputPublicNode } = globalState.queryBuild;
    let body = {
      continuationToken: "",
      inputPublicNode: {
        isin: inputPublicNode?.isin,
        productName: inputPublicNode?.productName,
        issuerLEI: inputPublicNode?.issuerLEI,
        issuerName: inputPublicNode?.issuerName,
        lastUpdateDate: inputPublicNode?.lastUpdateDate,
        historicVersion: props?.historicVersion
      },
    };
    dispatch(noMoreList(false));

    axios
      .post(QUERY_CRITERIA, body,{
        params:{
          page:0,
          limit:50,
        }
      })
      .then((res) => {
        dispatch(resetList(res));
        dispatch(appLoading(false));
      })
      .catch((err) => {
        dispatch(appLoading(false));
        dispatch(error());
      });
  };

  return (
    <>
      {expanded ? (
        <StyledGrid container>
          <Grid item xs={6}>
            <img className="display-inline" src={expandedImg} alt="expanded" />

            <Typography className="display-inline" variant="h1">
              {props.summary}
            </Typography>
          </Grid>
          {globalState.list.length >= 1 && (
            <Grid item xs={6} className="display-flex-end">
              <Button
                className="buttons"
                variant="contained"
                color="primary"
                onClick={refreshList}
              >
                Refresh
              </Button>
            </Grid>
          )}
        </StyledGrid>
      ) : (
        <>
          <img src={collapsedImg} className="display-inline" alt="collapsed" />
          <Typography className="display-inline" variant="h1">
            {props.summary}
          </Typography>
        </>
      )}
    </>
  );
}

export default ListViewAccordianSummary;
