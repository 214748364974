import {
  ADD_QUERY,
  QUERY_FIRED,
  EXPAND_VIEW,
  NO_LIST,
  QUERY_BUILD,
  QUERY_EXECUTION,
  RESET_LIST,
  SET_REFRESH_LIST,
  FETCH_LIST,
  FETCH_HISTORIC_LIST,
  ERROR,
  APP_LOADING,
} from "./StoreActionTypes";

//type of action dispatched to reducer , called by components
export const addQuery = (query) => ({
  type: ADD_QUERY,
  query,
});

export const queryExecutionTime = () => ({
  type: QUERY_EXECUTION,
});

export const queryBuild = (query) => ({
  type: QUERY_BUILD,
  query,
});

export const noMoreList = (val) => ({
  type: NO_LIST,
  val,
});

export const appLoading = (val) => ({
  type: APP_LOADING,
  val,
});

export const queryFired = (value) => ({
  type: QUERY_FIRED,
  value,
});

export const toggleExpandView = ({ query, list }) => ({
  type: EXPAND_VIEW,
  query,
  list,
});

export const resetListRefresh = (val) => ({
  type: SET_REFRESH_LIST,
  val,
});
export const addList = (res) => ({
  type: FETCH_LIST,
  list: res.data.publicnode,
  continuationToken: res.data.continuationToken,
});

export const addHistoricList = (res) => ({
  type: FETCH_HISTORIC_LIST,
  historicList: res?.data?.publicnode,
  continuationToken: res?.data?.continuationToken,
});

export const resetList = (res) => ({
  type: RESET_LIST,
  list: res.data.publicnode,
  continuationToken: res.data.continuationToken,
});

export const error = () => ({
  type: ERROR,
});
