import React from "react";
import { StyledDropdown } from "./index.css";
function Dropdown(props) {
  return (
    <StyledDropdown
      classNamePrefix="select"
      isClearable={props.isClearable}
      className={props.className}
      placeholder={props.placeholder}
      name={props.id}
      id={props.id}
      options={props.options}
      menuPortalTarget={document.body}
      onChange={props.onChange}
      value={props.value}
      styles={{
        menu: (base) => ({ ...base, borderRadius: "0px" }),
      }}
    />
  );
}

export default Dropdown;
