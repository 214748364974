import styled from "styled-components";

export const StyledNoDataDiv = styled.div`
  text-align: center;
  margin: 194px 40%;
  .nodata-typo {
    padding: "10px";
    white-space: nowrap;
  }
`;
