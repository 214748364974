import {
  ADD_QUERY,
  QUERY_FIRED,
  EXPAND_VIEW,
  NO_LIST,
  QUERY_BUILD,
  QUERY_EXECUTION,
  RESET_LIST,
  SET_REFRESH_LIST,
  FETCH_LIST,
  FETCH_HISTORIC_LIST,
  APP_LOADING,
} from "./StoreActionTypes";
import { initialState } from "./DefaultStates";

export const Reducer = (state = initialState, action) => {
  if (action.type === ADD_QUERY) {
    return {
      ...state,
      queries: { ...state.queries, ...action.query },
    };
  }

  if (action.type === QUERY_BUILD) {
    return {
      ...state,
      queryBuild: { ...state.queryBuild, ...action.query },
    };
  }
  if (action.type === SET_REFRESH_LIST) {
    return { ...state, listRefresh: action.val };
  }

  if (action.type === QUERY_FIRED) {
    return {
      ...state,
      queryFired: action.value,
      list: [],
    };
  }

  if (action.type === QUERY_EXECUTION) {
    return { ...state, queryExecutionTime: new Date() };
  }
  if (action.type === RESET_LIST) {
    return {
      ...state,
      list: [...(action?.list || [])],
      continuationToken: action.continuationToken,
      listRefresh: true,
    };
  }
  if (action.type === EXPAND_VIEW) {
    return {
      ...state,
      expandView: { query: action.query, list: action.list },
    };
  }
  if (action.type === FETCH_LIST) {
    return {
      ...state,
      list: [...state.list, ...(action?.list || [])],
      continuationToken: action.continuationToken,
    };
  }

  if (action.type === FETCH_HISTORIC_LIST) {
    return {
      ...state,
      historicList: action?.historicList || [],
      continuationToken: action?.continuationToken,
    };
  }
  if (action.type === APP_LOADING) {
    return {
      ...state,
      loading: action.val,
    };
  }
  if (action.type === NO_LIST) {
    return { ...state, noMoreList: action.val };
  } else {
    return { ...state };
  }
};
