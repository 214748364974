import React, { useState } from "react";
import collapsedImg from "../../../../../../../assets/expand-more.svg";
import expandedImg from "../../../../../../../assets/expand-less.svg";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { useStore } from "../../../../../../../store/Store";
import {
  addQuery,
  noMoreList,
  queryFired,
  error,
  queryExecutionTime,
} from "../../../../../../../store/StoreActions";
import { DEFAULT_VALUES } from "../QueryViewContent/components/SearchCriteria/constants";
import Typography from "@material-ui/core/Typography";
import { StyledGrid } from "./index.css";
import countArrow from "../../../../../../../assets/countArrow.svg";
import axios from "axios";
import {
  checkIfOneCriteriaEntered,
  queryBuilder,
} from "../../../../../../../components/Helpers/QueryNList";
import { QUERY_CRITERIA_COUNT } from "../../../../../../../config/ApiUrls";

function QueryAccordianSummary(props) {
  const [globalState, dispatch] = useStore();
  const [count, setCount] = useState({countWithFilter: 0, totalCount: 0});
  const [showCount, setShowCount] = useState(false);

  const updateStore = (event) => {
    dispatch(queryFired(true));
    dispatch(noMoreList(false));
    dispatch(queryExecutionTime());
  };

  const getCount = (event) => {
    event.stopPropagation();
    setTimeout(() => setShowCount(true), 300);

    let buildQuery = queryBuilder(globalState.queries);

    let body = {
      isin: buildQuery?.isin?.value,
      productName: buildQuery?.productName?.value,
      issuerLEI: buildQuery?.issuerLEI?.value,
      issuerName: buildQuery?.issuerName?.value,
      lastUpdateDate: buildQuery?.lastUpdateDate,
      historicVersion: props?.historicVersion,
    };

    axios
      .post(QUERY_CRITERIA_COUNT, body)
      .then((res) => {
        setCount(res.data);
        setTimeout(() => setShowCount(false), 10000);
      })
      .catch((err) => dispatch(error()));
  };

  const expanded = globalState.expandView.query;
  return (
    <>
      {expanded ? (
        <StyledGrid container>
          <Grid item xs={6}>
            <img className="display-inline" alt="expanded" src={expandedImg} />
            <Typography className="display-inline" variant="h1">
              {props.summary}
            </Typography>
          </Grid>
          <Grid item xs={6} align="right" className="display-flex-end">
            <Button
              className="buttons"
              variant="contained"
              color="primary"
              disabled={checkIfOneCriteriaEntered(globalState.queries)}
              onClick={updateStore}
            >
              Query
            </Button>

            <Button
              className="buttons"
              variant="contained"
              color="primary"
              disabled={checkIfOneCriteriaEntered(globalState.queries)}
              onClick={getCount}
            >
              Count
            </Button>
            <Button
              className="buttons"
              variant="contained"
              onClick={(event) => {
                event.stopPropagation();
                dispatch(addQuery(DEFAULT_VALUES));
                setShowCount(false);
              }}
              disabled={checkIfOneCriteriaEntered(globalState.queries)}
              color="primary"
            >
              Reset
            </Button>
          </Grid>

          {showCount && (
            <Grid container className="responsive-div" item xs={12}>
              <Grid item xs={6} className="flex-basis"></Grid>
              <Grid item xs={6} align="right">
                <img alt="pointer" className="count-arrow" src={countArrow} />
                <div className="count-div">
                  <Typography variant="h5" className="text">
                    {`Active: ${count?.countWithFilter} instruments | Total: ${count?.totalCount} issued instruments into D7`}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          )}
        </StyledGrid>
      ) : (
        <>
          <img className="display-inline" src={collapsedImg} alt="collapsed" />
          <Typography className="display-inline" variant="h1">
            {props.summary}
          </Typography>
        </>
      )}
    </>
  );
}

export default QueryAccordianSummary;
