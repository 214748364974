import React, { useState } from "react";
import PropTypes from "prop-types";
import { StyledDiv } from "./index.css";
import { StyledCustomTooltip } from "./index.css";

EllipisWithTooltip.propTypes = {
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

EllipisWithTooltip.defaultProps = {
  placement: undefined,
  className: undefined,
};

function EllipisWithTooltip(props) {
  const { placement = "top-start", className = "", children, onClick } = props;
  const [hasOverflowingChildren, setHasOverflowingChildren] = useState(false);

  const updateOverflow = (e) => {
    const el = e.target;

    if (!hasOverflowingChildren && el.scrollWidth > el.clientWidth) {
      setHasOverflowingChildren(true);
    } else {
      setHasOverflowingChildren(false);
    }
  };

  return hasOverflowingChildren ? (
    <StyledCustomTooltip title={children} data-testid="Tooltip-div-1" placement={placement}>
      <StyledDiv onClick={props?.onClick} className={className}>{children}</StyledDiv>
    </StyledCustomTooltip>
  ) : (
    <StyledDiv onClick={props?.onClick} data-testid="Tooltip-div-2" className={className} onMouseEnter={updateOverflow}>
      {children}
    </StyledDiv>
  );
}

export default EllipisWithTooltip;
