import { Typography, Grid } from "@material-ui/core";
import React from "react";
import { StyledFooter } from "./StyledFooter";

function Footer() {
  return (
    <StyledFooter container>
        <div className="footer-link">
      <Grid xs item className="gird-item" align="right">
        <a
          href="https://www.clearstream.com/clearstream-en/privacy-policy-1277786"
          target="_blank"
        >
          <Typography variant="h5" className="typo">
            Cookies Policy
          </Typography>
        </a>
      </Grid>
      </div>
    </StyledFooter>
  );
}
export default Footer;