import styled from "styled-components";
import { Menu } from "@material-ui/core";

export const StyledOperatorMenu = styled.div`
  color: #00a7c4;
  font-weight: 900;
  line-height: 1.5;
`;

export const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: none;
    margin-left: -35px;
    border-radius: 0px;
  }
  .MuiMenu-list {
    display: flex;
    height: 30px;
  }
  .MuiList-padding {
    padding: 0px;
  }
  .MuiMenuItem-root {
    color: #00a7c4;
    font-weight: 900;
    width: 30px;
    padding-top: 6px;
    font-size: 1rem;
    font-family: "FFDINMEDIUM";
    justify-content: center;
  }
  .Muilist-root {
    padding: 0px;
  }
`;
