import styled from "styled-components";
import Tabs from "@material-ui/core/Tabs";

export const StyledModuleNavigation = styled(Tabs)`
  border-bottom: 2px solid #99cc33;
  margin-bottom: 5px;
  &.MuiTabs-root {
    min-height: 34px !important;
    height: 34px;
  }
  .MuiTab-root {
    min-height: 34px;
    border-right: 1px solid white;
  }

  .MuiTabs-indicator {
    background-color: #99cc33;
  }
  .nav-tab {
    text-transform: none;
    background-color: #00a7c4;
  }
  .active-nav-tab {
    text-transform: none;
    background-color: #99cc33;
  }
  .MuiTab-wrapper {
    font-size: 14px;
    color: white;
    font-family: "FFDINBOLD";
  }
  .MuiTab-textColorInherit {
    opacity: unset;
  }
  .MuiTabScrollButton-root.Mui-disabled {
    display: none;
  }
  .MuiTabScrollButton-root {
    color: black;
    background-color: #d3f3f7;
  }
  .termsLink:hover{
    text-decoration: underline;
    text-decoration-color: white;
  }
`;
