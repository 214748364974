import React, { useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import Paper from "@material-ui/core/Paper";
import { useStore } from "../../../../../../../store/Store";
import axios from "axios";
import { DOWNLOAD_FILE } from "../../../../../../../config/ApiUrls";
import moment from "moment";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
  addList,
  error,
  noMoreList,
  queryExecutionTime,
  resetListRefresh,
  appLoading,
} from "../../../../../../../store/StoreActions";
import {
  StyledTableContainer,
  StyledTableBody,
  StyledTableRow,
} from "./index.css";
import TablePagination from "../../../../../../../components/Table/components/Pagination";
import { ROW_PER_PAGE_OPTIONS } from "./constants";
import { QUERY_CRITERIA } from "../../../../../../../config/ApiUrls";
import {
  getComparator,
  stableSort,
} from "../../../../../../../components/Table/helpers/Sorting";
import EnhancedTableHead from "../../../../../../../components/Table/components/EnhancedTableHead";
import { headCells } from "./constants";

import EllipisWithTooltip from "../../../../../../../components/EllipsisWithToolTip";
import SimpleModal from "../../../../../../../components/Modal";
function ListViewContent() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [globalState, dispatch] = useStore();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [list, setList] = React.useState(globalState.list);
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const loadMorePages = () => {
    dispatch(appLoading(true));
    let { inputPublicNode } = globalState.queryBuild;
    let body = {
      continuationToken:"",
      inputPublicNode: {
        isin: inputPublicNode?.isin,
        productName: inputPublicNode?.productName,
        issuerLEI: inputPublicNode?.issuerLEI,
        issuerName: inputPublicNode?.issuerName,
        lastUpdateDate: inputPublicNode?.lastUpdateDate,
        historicVersion: false,
      },
    };

    axios
      .post(QUERY_CRITERIA, body,{
        params:{
          page:Math.ceil(list.length / 50),
          limit:50,
        }
      })
      .then((res) => {
        if (res.data.publicnode.length === 0) {
          dispatch(noMoreList(true));
        } else {
          dispatch(addList(res));
        }
        dispatch(appLoading(false));
      })
      .catch((err) => {
        dispatch(error());
        dispatch(appLoading(false));
      });
    return;
  };

  const handleChangeRowsPerPage = (option) => {
    const rowsCount = parseInt(option.value, 10);
    setRowsPerPage(rowsCount);
    setPage(0);
  };

  const handleGotoPageNumber = (pageNumber) => {
    setPage(pageNumber);
  };

  const getFile = (parameter, generationId) => (event) => {
    const generationIdExt = `&generationId=${
      generationId ? generationId : ""
    }&historicVersion=false`;
    window.open(DOWNLOAD_FILE + parameter + generationIdExt, "_blank");
  };

  useEffect(() => {
    if (globalState.listRefresh) {
      setOrder("asc");
      setOrderBy("");
      dispatch(queryExecutionTime());
      dispatch(resetListRefresh(false));
    }
    if (globalState.list) {
      setList(globalState.list);
    }
  }, [globalState.list]);

  return (
    <div>
      <StyledTableContainer component={Paper}>
        <Table id="table-custom" aria-label="customized table">
          <EnhancedTableHead
            rowCount={list.length}
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
            headCells={headCells}
          />

          <StyledTableBody>
            {stableSort(list, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((list, index) => {
                return (
                  <StyledTableRow>
                    <TableCell id="isin" align="left">
                      <EllipisWithTooltip className="overflow-td">
                        {list?.isin || "-"}
                      </EllipisWithTooltip>
                    </TableCell>
                    <TableCell id="pnVersionNo" align="left">
                      <EllipisWithTooltip className="overflow-td">
                        {list?.pnVersionNo || "-"}
                      </EllipisWithTooltip>
                    </TableCell>
                    <TableCell id="productName" align="left">
                      <EllipisWithTooltip className="overflow-td">
                        {list?.productName || "-"}
                      </EllipisWithTooltip>
                    </TableCell>
                    <TableCell id="issuerName" align="left">
                      <EllipisWithTooltip className="overflow-td">
                        {list?.issuerName || "-"}
                      </EllipisWithTooltip>
                    </TableCell>
                    <TableCell id="lastUpdateDate" align="left">
                      <EllipisWithTooltip className="overflow-td">
                        {list?.lastUpdateDate
                          ? moment
                              .utc(list?.lastUpdateDate)
                              .tz("Europe/Berlin")
                              .format("DD/MM/YYYY HH:mm:ss")
                          : "-"}
                      </EllipisWithTooltip>
                    </TableCell>
                    <TableCell id="issuerLEI" align="left">
                      <EllipisWithTooltip className="overflow-td">
                        {list?.issuerLEI || "-"}
                      </EllipisWithTooltip>
                    </TableCell>
                    <td
                      id="history"
                      align="center"
                      style={{ border: "1px solid #a5e6ee" }}
                    >
                      <SimpleModal isin={list?.isin} />
                    </td>
                    <td
                      id="t&c"
                      align="center"
                      style={{ border: "1px solid #a5e6ee" }}
                    >
                      <GetAppIcon
                        onClick={getFile(list.isin, list?.generationId)}
                        className="download"
                        aria-hidden="true"
                      />
                    </td>
                  </StyledTableRow>
                );
              })}
          </StyledTableBody>
        </Table>
      </StyledTableContainer>
      <TablePagination
        loadMorePages={loadMorePages}
        totalRows={list.length}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        gotoPageNumber={handleGotoPageNumber}
        rowPerPageOptions={ROW_PER_PAGE_OPTIONS}
        lastPage={Math.ceil(list.length / rowsPerPage)}
        noMorePages={globalState.noMoreList}
        listRefresh={globalState.listRefresh}
        displayTime={globalState.queryExecutionTime}
      />
    </div>
  );
}

export default ListViewContent;
