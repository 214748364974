import React from "react";
import Header from "./components/Header";
import { StyledApp } from "./StyledApp";
import ModuleNavigation from "./components/ModuleNavigation";
import { StyledDialog } from "./StyledApp";
import { CircularProgress } from "@material-ui/core";
import { useStore } from "./store/Store";
import Footer from "./components/Footer/footer";

function App() {
  const [globalState] = useStore();
  const { loading } = globalState;
  return (
    <StyledApp>
      <div className="container">
      <Header />
      <ModuleNavigation />
      <StyledDialog open={loading}>
        <CircularProgress />
      </StyledDialog>
      </div>
      <Footer/>
    </StyledApp>
  );
}

export default App;
