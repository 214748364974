import React from "react";
import { StyledFormView } from "./StyledFormView";
import { Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
function FormView(props) {
  return (
    <>
      <hr className="divider" />

      <StyledFormView container>
        <Grid item xs={12}>
          <Typography variant="h5" className="title">
            {props.title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <div id="forms" className="forms">
            {props.children}
          </div>
        </Grid>
      </StyledFormView>
    </>
  );
}

export default FormView;
