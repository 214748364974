import { cloneDeep } from "lodash";

export const queryBuilder = (state) => {
  let buildQuery = {};
  for (let [key, value] of Object.entries(cloneDeep(state))) {
    if (value.value || (value.range?.from && value.range?.to)) {
      buildQuery = { ...buildQuery, [key]: value };
    }
  }

  return buildQuery;
};

export const checkIfOneCriteriaEntered = (state) => {
  let val = true;
  for (const [, id] of Object.entries(state)) {
    if (!!id.value || (!!id?.range?.from && !!id?.range?.to)) val = false;
  }
  return val;
};
