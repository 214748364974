import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const StyledSearchCriteria = styled(Grid)`
  .text-field {
    width: 80%;
    padding-bottom: 8px;
  }

  .range-date-picker {
    width: 78%;
    margin-bottom: 8px;
    height: 30px;
    margin-left: 25px;
  }
  .single-date-picker {
    width: 77%;
    margin-bottom: 8px;
    height: 30px;
    margin-left: 10px;
  }
  .ant-picker {
    padding: 0px !important;
    padding-left: 10px !important;
  }

  .MuiOutlinedInput-input {
    padding: 0;
  }
  .MuiInputBase-input {
    padding: 10px;
  }
  .MuiOutlinedInput-input {
    height: 10px;
  }
  .operators-menu {
    padding-top: 5px;
    font-size: 1rem;
    text-align: center;
    width: 30px;
    background-color: white;
    height: 30px;
    font-family: "FFDINMedium";
    color: #00a7c4;
    font-weight: 900;
  }
  .item-color {
    color: #00a7c4;
  }
  .range-width {
    width: 85% !important;
  }
  .grid-flex {
    display: flex;
  }
  .MuiTextField-root {
    padding-left: 10px;
  }
  .elem-padding {
    padding-left: 10px;
  }
  .elem-padding-20 {
    padding-left: 20px;
  }
  .select-styles {
    width: 80%;
    padding-left: 10px;
    padding-bottom: 8px;
  }
`;
