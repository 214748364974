export const ADD_QUERY = "ADD_QUERY";
export const QUERY_FIRED = "QUERY_FIRED";
export const EXPAND_VIEW = "EXPAND_VIEW";
export const NO_LIST = "NO_LIST";
export const FETCH_LIST = "FETCH_LIST";
export const FETCH_HISTORIC_LIST = "FETCH_HISTORIC_LIST";
export const QUERY_BUILD = "QUERY_BUILD";
export const RESET_LIST = "RESET_LIST";
export const SET_REFRESH_LIST = "SET_REFRESH_LIST";
export const QUERY_EXECUTION = "QUERY_EXECUTION";
export const ERROR = "ERROR";
export const APP_LOADING = "APP_LOADING";
