import React from "react";
import { Typography } from "@material-ui/core";

const OPERATOR_TO_TITLE_MAP = {
  "=": `'=' Equal to`,
  "[]": `'[]' Between`,
};

function TooltipTitle(props) {
  return (
    <div>
      <Typography variant="subtitle2" className={`${props.operatorsAllowed?"bottom-border":""}`}>
        {OPERATOR_TO_TITLE_MAP[props.operator]}
      </Typography>
      {props.operatorsAllowed && <Typography variant="subtitle2" className="font-regular">
        Click for more options
      </Typography>}
    </div>
  );
}

export default TooltipTitle;