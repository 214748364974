export const headCellsModal = [
  {
    id: "isin",
    numeric: false,
    label: "ISIN",
  },
  {
    id: "pnVersionNo",
    numeric: false,
    label: "Version No",
  },
  {
    id: "productName",
    numeric: false,
    label: "Product Name",
  },
  {
    id: "issuerName",
    numeric: false,
    label: "Issuer Name",
  },

  {
    id: "lastUpdateDate",
    numeric: false,
    label: "Last Update Date",
  },
  {
    id: "issuerLEI",
    numeric: false,
    label: "Issuer LEI",
  },

  {
    id: "t&c",
    numeric: false,
    label: "T&C PDF",
  },
];

export const headCells = [
  {
    id: "isin",
    numeric: false,
    label: "ISIN",
  },
  {
    id: "pnVersionNo",
    numeric: false,
    label: "Version No",
  },
  {
    id: "productName",
    numeric: false,
    label: "Product Name",
  },
  {
    id: "issuerName",
    numeric: false,
    label: "Issuer Name",
  },

  {
    id: "lastUpdateDate",
    numeric: false,
    label: "Last Update Date",
  },
  {
    id: "issuerLEI",
    numeric: false,
    label: "Issuer LEI",
  },
  {
    id: "history",
    numeric: false,
    label: "History",
  },

  {
    id: "t&c",
    numeric: false,
    label: "T&C PDF",
  },
];

export const ROW_PER_PAGE_OPTIONS = [
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
];
