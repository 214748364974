import React, { useEffect } from "react";
import { MenuItem } from "@material-ui/core";
import { StyledOperatorMenu, StyledMenu } from "./StyledOperatorMenu";
import CustomTooltip from "../../../../../../../../../../../components/CustomTooltip";
import { DEFAULT_VALUES, OPERATORS_ALLOWED } from "../../constants";
import TooltipTitle from "./components/Tooltip/TooltipTitle";

function OperatorMenu(props) {
  const defaultOperator = DEFAULT_VALUES[props.parent]?.operator;
  const [operator, setOperator] = React.useState(
    props.operator || defaultOperator
  );

  useEffect(() => {
    setOperator(props.operator);
  }, [props.operator]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const changeOperator = (event) => {
    const value = event.target.id;
    props.setOperator(props.parent, value);
    setOperator(value);
    setAnchorEl(null);
  };

  const handleClose = (event) => {
    setAnchorEl(null);
  };
  return (
    <CustomTooltip
      title={<TooltipTitle operatorsAllowed={OPERATORS_ALLOWED[props.parent]} operator={operator} />}
      placement="top-start"
    >
      <StyledOperatorMenu
        className="operators-menu"
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={!anchorEl && handleClick}
      >
        {operator}
        {OPERATORS_ALLOWED[props.parent] &&
        <StyledMenu
         options={{ autoWidth: false }}
         autoWidth={false}
          id="fade-menu"
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleClose}
        >
          {OPERATORS_ALLOWED[props.parent]?.map((operator, key) => (
            <MenuItem id={operator} key={key} onClick={changeOperator}>
              {operator}
            </MenuItem>
          ))}
        </StyledMenu>}
      </StyledOperatorMenu>
    </CustomTooltip>
  );
}

export default OperatorMenu;
