import styled from "styled-components";
import Select from "react-select";

export const StyledDropdown = styled(Select)`
  .select__placeholder {
    color: darkgray;
    top: 45%;
  }

  .select__control {
    border-radius: 0;
    height: 30px;
    min-height: 30px;
    .select__indicator-separator {
      display: none;
    }
    .select__indicators {
      height: 30px;
    }
    .select__dropdown-indicator {
      color: #00a7c4;
      padding-right: 12px;
    }
  }
`;
