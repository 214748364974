import styled from "styled-components";
import { Tooltip } from "@material-ui/core";

export const StyledCustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))`
  & .MuiTooltip-tooltip {
    background: white;
    border: 1px solid #00a4c7;
    color: black;
    border-radius: 0;
  }
  .MuiTooltip-arrow {
    font-size: 16;
    width: 17;
    &::before {
      border: 1px solid #00a4c7;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .font-regular {
    font-size: 12px;
    font-family: "FFDINRegular";
  }
  .bottom-border {
    border-bottom: 1px solid #a8bdc1;
    padding-bottom: 7px;
    margin-bottom: 5px;
  }
`;
