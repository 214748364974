import styled from "styled-components";

export const StyledTablePagination = styled.div`
  display: flex;
  .left-div {
    display: flex;
  }
  .right-div {
    display: flex;
    margin-top: 10px;
  }
  .MuiButton-root {
    min-width: 30px;
    height: 30px;
  }
  .MuiButton-text {
    padding: 0;
  }
  .basic-single {
    padding-top: 10px;

    .select__control {
      border-radius: 0;
      height: 30px;
      min-height: 30px;
      width: 67px;

      .select__indicator-separator {
        display: none;
      }
      .select__indicators {
        height: 30px;
      }
      .select__dropdown-indicator {
        color: #00a7c4;
        padding-right: 12px;
      }
      .select_indicator {
        padding: 0;
      }
    }
  }
  .MuiTypography-root {
    padding: 15px;
  }
  .buttons {
    height: 30px;
    width: 57px;
    white-space: nowrap;
    white-space: nowrap;
  }
  .active-div-button {
    background-color: #00a7c4;
    color: #ffffff !important;
  }
  .div-button {
    height: 30px;
    width: 30px;
    color: #00a7c4;
    white-space: nowrap;
  }
  .MuiOutlinedInput-input {
    padding: 6px 6px 7px;
    width: 50px;
  }

  .align-center {
    text-align: center;
  }
  .img-disabled {
    opacity: 0.5;
  }
  img {
    padding-right: 0;
  }
  .nowrap {
    white-space: nowrap;
  }
`;
